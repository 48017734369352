import AdminMasterdataEditRow from '@components/admin/components/AdminMasterdataEditRow.tsx';
import FormBodyHeader from '@components/admin/components/form/FormBodyHeader.tsx';
import { FormHeaderNav } from '@components/admin/components/form/FormHeaderNav.tsx';
import FormInputField from '@components/admin/components/form/FormInputField.tsx';
import FormSection from '@components/admin/components/form/FormSection.tsx';
import SearchResultCardSimple from '@components/document/labeler/sidebar/search/SearchResultCardSimple.tsx';
import ConfirmationDialog from '@components/shared/confirmation-dialog/ConfirmationDialog.tsx';
import { DropdownOption } from '@components/shared/dropdown/StyledSelect.tsx';
import Tooltip from '@components/shared/tooltip/Tooltip.tsx';

import {
  IClientMasterdata,
  masterdataClientToRaw,
  masterdataRawToClient,
} from '@shared/helpers/converters/masterdata.ts';
import useChangeTracker, { ChangeSaveCallback } from '@shared/hooks/useChangeTracker.tsx';
import useConsole from '@shared/hooks/useConsole.tsx';
import { useModal } from '@shared/hooks/useModal.tsx';
import {
  adminSlice,
  deleteMasterdataTable,
  getTableCSV,
  patchMasterdataTable,
  postMasterdataTable,
  postTableVersionData,
} from '@shared/store/adminSlice.ts';
import { MasterDataSearchPayload, searchMasterData } from '@shared/store/documentSlice.ts';
import { useDispatch, useSelector } from '@shared/store/store.ts';
import se from '@shared/styles/component/admin/admin-masterdata.module.scss';
import s from '@shared/styles/component/admin/admin-section.module.scss';
import { ReactComponent as InfoIcon } from '@svg/error-icon.svg';
import { Pulsar } from '@uiball/loaders';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import { cloneDeep, debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const AdminInboxesMasterdataEdit: React.FC = () => {
  const inboxEntityTypes = useSelector((state) => state.admin.inboxEntityTypes);
  const inboxMasterdataTables = useSelector((state) => state.admin.inboxMasterdataTables);
  const inboxMasterdataVersions = useSelector((state) => state.admin.inboxMasterdataVersions);
  const inboxMetadataTypes = useSelector((state) => state.admin.inboxMetadataTypes);
  const masterDataResults = useSelector((state) => state.document.masterDataResults);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const { inboxId, masterdataId } = useParams();
  const { t } = useTranslation();
  const { showDialog } = useModal();
  const dispatch = useDispatch();

  const handleInput = (value: any, field: string) => {
    setState((fs) => {
      const copy = cloneDeep(fs);
      copy[field] = value;
      return copy;
    });
  };
  const [searchInput, setSearchInput] = useState('');

  const activeMasterdataTable = useMemo(() => {
    if (masterdataId !== 'new' && inboxMasterdataTables) {
      const table = inboxMasterdataTables.find((table) => table.id === masterdataId);
      if (!table) return null;
      const mapped = masterdataRawToClient(table, inboxMasterdataVersions ?? []);
      return mapped;
    }
    return null;
  }, [inboxMasterdataTables, inboxMasterdataVersions, masterdataId]);

  const handleFileUpload = async (file: File) => {
    return postTableVersionData(inboxId, file, activeMasterdataTable.id);
  };

  const handleDelete = () => {
    const tableId = activeMasterdataTable.id;
    showDialog(
      <ConfirmationDialog
        confirmAction={() => {
          return dispatch(deleteMasterdataTable(inboxId, tableId)).then(() => {
            navigate(`/admin/inboxes/${inboxId}/masterdata`);
          });
        }}
        text={t('admin:inboxes.sections.masterdataTableDelete')}
      />
    );
  };

  const handleSave: ChangeSaveCallback<IClientMasterdata> = async () => {
    const payload = masterdataClientToRaw(state);
    const parseError = (errorCode) => {
      if (errorCode === 409) {
        setErrorMessage(t('admin:masterdata.errorExists'));
      } else return setErrorMessage(t('admin:masterdata.errorGeneric'));
    };
    let func;
    if (activeMasterdataTable) {
      func = () => patchMasterdataTable(inboxId, activeMasterdataTable.id, payload);
    } else {
      delete payload.mapping;
      func = () => postMasterdataTable(inboxId, payload);
    }
    return func()
      .then((res) => {
        dispatch(adminSlice.actions.setActiveMasterdataId(null));
        navigate(`/admin/inboxes/${inboxId}/masterdata`);
        return res;
      })
      .catch((err: AxiosError) => parseError(err.code));
  };
  const [options, setOptions] = useState<any[]>();

  const handleNavBack = () => {
    if (hasChanges) {
      showDialog(
        <ConfirmationDialog
          confirmAction={() => {
            save(null);
            navigate(`/admin/inboxes/${inboxId}/masterdata`);
          }}
          cancelAction={() => navigate(`/admin/inboxes/${inboxId}/masterdata`)}
          confirmText={t('document:dialog.save')}
          cancelText={t('document:dialog.discard')}
          dialogType={'confirmation'}
          title={t('admin:unsavedChanges.title')}
          text={t('admin:unsavedChanges.description')}
        />
      );
    } else {
      navigate(`/admin/inboxes/${inboxId}/masterdata`);
    }
  };

  const groupedOptions: any[] = useMemo(() => {
    if (!inboxEntityTypes || !inboxMetadataTypes) return [];
    const list: any = [];
    const ungrouped = [];
    const fieldTypes = [];
    inboxEntityTypes?.forEach((imt) => {
      const item: DropdownOption = {
        label: imt.name,
        tag: { name: 'Field', value: 'entity_types' },
        value: imt.id,
        color: '#0085FF',
      };
      ungrouped.push(item);
      fieldTypes.push(item);
    });

    list.push({ label: 'Field', options: fieldTypes });

    const metaTypes = [];
    inboxMetadataTypes.forEach((imt) => {
      const item: DropdownOption = {
        label: imt.name,
        tag: { name: 'Metadata', value: 'metadata_keys' },
        value: imt.id,
        color: '#6600ff',
      };
      ungrouped.push(item);
      metaTypes.push(item);
    });
    list.push({ label: 'Metadata', options: metaTypes });
    setOptions(ungrouped);
    return list;
  }, [inboxEntityTypes, inboxMetadataTypes]);

  const initialState = useMemo(() => {
    if (!activeMasterdataTable) {
      return {
        name: '',
        boost: 1,
        mapping: [],
        lookup: false,
      };
    } else return activeMasterdataTable;
  }, [activeMasterdataTable]);

  const { save, state, setState, saving, hasChanges } = useChangeTracker(initialState, handleSave);

  const [isDownloadingCSV, setIsDownloadingCSV] = useState(false);

  const nextTable = useMemo(() => {
    if (masterdataId && inboxMasterdataTables) {
      const sorted = [...inboxMasterdataTables].sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      const index = sorted.findIndex((e) => e.id === masterdataId);
      return sorted[index + 1];
    }
    return null;
  }, [masterdataId, inboxMasterdataTables]);

  const prevTable = useMemo(() => {
    if (masterdataId && inboxMasterdataTables) {
      const sorted = [...inboxMasterdataTables].sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      const index = sorted.findIndex((e) => e.id === masterdataId);
      return sorted[index - 1];
    }
    return null;
  }, [masterdataId, inboxMasterdataTables]);

  useEffect(() => {
    const debouncedHandleSearch = debounce(() => {
      const payload: MasterDataSearchPayload = {
        prompt: searchInput,
        fields: [],
        table_ids: [state.id],
      };
      dispatch(searchMasterData(payload, inboxId));
    }, 300);

    debouncedHandleSearch();
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [dispatch, inboxId, searchInput, state]);

  useConsole(masterDataResults);

  const handleNavNextTable = () => {
    if (nextTable) {
      navigate(`/admin/inboxes/${inboxId}/masterdata/${nextTable.id}`);
    }
  };

  const handleNavPrevTable = () => {
    if (prevTable) {
      navigate(`/admin/inboxes/${inboxId}/masterdata/${prevTable.id}`);
    }
  };

  return (
    <form onSubmit={save} className={s.form_body}>
      <FormHeaderNav
        onClick={handleNavBack}
        label={t('admin:page.backToOverview')}
        navOptions={
          masterdataId === 'new'
            ? {}
            : {
                prev: {
                  label: t('admin:page.previous'),
                  onClick: handleNavPrevTable,
                  active: !!prevTable,
                },
                next: {
                  label: t('admin:page.next'),
                  onClick: handleNavNextTable,
                  active: !!nextTable,
                },
              }
        }
      />
      <FormBodyHeader
        hasChanges={hasChanges}
        saving={saving}
        errorMessage={errorMessage}
        title={activeMasterdataTable ? activeMasterdataTable.name : t('admin:masterdata.title')}
      />
      <div className={s.sections}>
        <FormSection title={'Data'} hidden={masterdataId === 'new'}>
          <FormInputField
            isCopyField
            value={state.id}
            type={'text'}
            label={t('admin:masterdata.id')}
            description={t('admin:masterdata.idDescription')}
          />

          <FormInputField
            disabled
            value={activeMasterdataTable?.uploadTime?.toLocaleString('nl-BE', {
              dateStyle: 'medium',
              timeStyle: 'short',
            })}
            type={'text'}
            label={t('admin:masterdata.latestUpload')}
            description={t('admin:masterdata.latestUploadDescription')}
          />
          <FormInputField
            testId={'line-count-input'}
            disabled
            value={activeMasterdataTable?.totalCount}
            type={'text'}
            label={t('admin:masterdata.totalLineCount')}
            description={t('admin:masterdata.totalLineCountDescription')}
          />
          <FormInputField
            testId={'upload-table'}
            value={null}
            type={'upload'}
            label={t('admin:masterdata.uploadData')}
            description={t('admin:masterdata.uploadDataDescription')}
            uploadOptions={{
              errorMessage: t('admin:masterdata.errorMismatch'),
              handleUpload: handleFileUpload,
              accept: 'text/csv',
            }}
          />
          <FormInputField
            hidden={activeMasterdataTable?.uploadTime == null}
            value={null}
            type={'button'}
            label={t('admin:masterdata.downloadCSV')}
            description={t('admin:masterdata.downloadCSVDescription')}
            buttonOptions={{
              text: isDownloadingCSV ? (
                <>
                  <Pulsar size={14} color={'white'} /> Downloading
                </>
              ) : (
                t('admin:masterdata.downloadCSV')
              ),
              type: 'blue',
              onClick: async () => {
                setIsDownloadingCSV(true);
                const res = await getTableCSV(inboxId, activeMasterdataTable?.id);
                const csvData = res.data;
                const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                  'download',
                  `${activeMasterdataTable.name}-${activeMasterdataTable.uploadTime.toLocaleString()}.csv`
                );
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setIsDownloadingCSV(false);
              },
            }}
          />
        </FormSection>
        <FormSection title={'Configuration'}>
          <FormInputField
            testId={'masterdata-name-input'}
            value={state.name}
            description={t('admin:inboxes.nameDescription')}
            type={'text'}
            label={t('admin:inboxes.name')}
            onChange={(val) => handleInput(val, 'name')}
          />
          <FormInputField
            value={state.lookup}
            description={t('admin:masterdata.lookupDescription')}
            type={'toggle'}
            label={t('admin:masterdata.lookup')}
            onChange={() => handleInput(!state?.lookup, 'lookup')}
          />
          <FormInputField
            numberInputOptions={{
              label: '',
              min: 0,
              max: 10,
              step: 0.01,
            }}
            value={state.boost}
            description={t('admin:masterdata.boostDescription')}
            type={'number'}
            label={t('admin:masterdata.boost')}
            onChange={(val) => {
              if (val === '') {
                handleInput('', 'boost');
                return;
              }
              let value = parseFloat(val);
              if (value > 10) value = 10;
              handleInput(value, 'boost');
            }}
            placeholder={'1'}
          />

          {masterdataId !== 'new' && (
            <div className={clsx(s.item, s.item__vertical)}>
              <div className={s.item_text}>
                <h4>{t('admin:masterdata.tableMapping')}</h4>
                <p>{t('admin:masterdata.tableMappingDescription')}</p>
              </div>
              <div className={clsx(s.item_action, se.mapping)}>
                <div className={se.mapping_row}>
                  <div className={se.mapping_row_header}>
                    <span>{t('admin:masterdata.headerLabel')}</span>
                    <Tooltip content={t('admin:masterdata.headerLabelTooltip')}>
                      <div>
                        <InfoIcon />
                      </div>
                    </Tooltip>
                  </div>
                  <div
                    style={{ maxWidth: 200, marginRight: 0, marginLeft: 'auto' }}
                    className={clsx(se.mapping_row_header, se.mapping_row_header__center)}
                  >
                    <span>{t('admin:masterdata.headerMapping')}</span>
                    <Tooltip
                      position="bottom"
                      content={
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                          {t('admin:masterdata.headerMappingTooltip')
                            .split('\n')
                            .map((str, index) => {
                              return <span key={index}>{str}</span>;
                            })}
                        </div>
                      }
                    >
                      <div>
                        <InfoIcon />
                      </div>
                    </Tooltip>
                  </div>
                  <div className={se.mapping_row_header}>
                    <span>{t('admin:masterdata.headerDisplay')}</span>
                    <Tooltip position="bottom" content={<p>{t('admin:masterdata.headerDisplayTooltip')}</p>}>
                      <div>
                        <InfoIcon />
                      </div>
                    </Tooltip>
                  </div>
                  <div className={clsx(se.mapping_row_header, se.mapping_row_header__center)}>
                    <span>{t('admin:masterdata.headerChars')}</span>
                    <Tooltip position="bottom" content={<p>{t('admin:masterdata.headerCharsTooltip')}</p>}>
                      <div>
                        <InfoIcon />
                      </div>
                    </Tooltip>
                  </div>
                  <div
                    style={{ maxWidth: 110 }}
                    className={clsx(se.mapping_row_header, se.mapping_row_header__center)}
                  >
                    <span>{t('admin:masterdata.headerType')}</span>
                    <Tooltip
                      position="bottom"
                      content={
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                          {t('admin:masterdata.headerTypeTooltip')
                            .split('\n')
                            .map((str, index) => {
                              return <span key={index}>{str}</span>;
                            })}
                        </div>
                      }
                    >
                      <div>
                        <InfoIcon />
                      </div>
                    </Tooltip>
                  </div>

                  <div
                    style={{ maxWidth: 115 }}
                    className={clsx(se.mapping_row_header, se.mapping_row_header__center)}
                  >
                    <span>{t('admin:masterdata.headerPin')}</span>
                    <Tooltip position="bottom" content={<p>{t('admin:masterdata.headerPinTooltip')}</p>}>
                      <div>
                        <InfoIcon />
                      </div>
                    </Tooltip>
                  </div>

                  <div
                    style={{ maxWidth: 115 }}
                    className={clsx(se.mapping_row_header, se.mapping_row_header__center)}
                  >
                    <span>{t('admin:masterdata.headerSearchable')}</span>
                    <Tooltip
                      position="bottom"
                      content={
                        <p style={{ maxWidth: 170 }}>{t('admin:masterdata.headerSearchableTooltip')}</p>
                      }
                    >
                      <div>
                        <InfoIcon />
                      </div>
                    </Tooltip>
                  </div>

                  <div style={{ maxWidth: 70 }} className={se.mapping_row_header}></div>
                </div>
                <div className={se.mapping_rows}>
                  {options &&
                    state?.mapping.map((e, i) => {
                      return (
                        <AdminMasterdataEditRow
                          mapping={e}
                          key={e.id}
                          options={options}
                          groupedOptions={groupedOptions}
                          handleDeleteRow={() => {
                            setState((og) => {
                              const clone = cloneDeep(og);
                              clone.mapping = clone.mapping.filter((item) => item.id !== e.id);
                              return clone;
                            });
                          }}
                          handleChange={(updated) => {
                            setState((og) => {
                              const clone = cloneDeep(og);
                              clone.mapping[i] = updated;
                              return clone;
                            });
                          }}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          )}
        </FormSection>
        <FormSection title={'Search'}>
          <FormInputField
            label={'Masterdata search'}
            description={'Allows you to perform a global search in the current masterdata table'}
            type={'text'}
            value={searchInput}
            onChange={(v) => {
              console.log(v);
              setSearchInput(v);
            }}
          />
          {masterDataResults && (
            <div
              style={{
                display: 'grid',
                flexDirection: 'column',
                gap: 20,
                gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 400px))',
                padding: 20,
              }}
            >
              {masterDataResults.map((e) => {
                return <SearchResultCardSimple result={e} key={e.id} />;
              })}
            </div>
          )}
        </FormSection>
        {masterdataId !== 'new' && (
          <>
            <FormSection hidden={masterdataId === 'new'} title={t('admin:masterdata.dangerZone')}>
              <FormInputField
                testId={'masterdata-delete'}
                type={'button'}
                buttonOptions={{
                  type: 'error',
                  text: t('admin:masterdata.delete'),
                  onClick: handleDelete,
                }}
                label={t('admin:masterdata.delete')}
                description={t('admin:masterdata.deleteDescription')}
              />
            </FormSection>
          </>
        )}
      </div>
    </form>
  );
};
export default AdminInboxesMasterdataEdit;
